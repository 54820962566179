<div>
   <h4 class="pb-2 date">{{ news.published_at | date: 'd MMMM YYYY' }}</h4>
   <div
      [routerLink]="['/news', news.slug]"
      class="img-fluid news-div-img"
      style="background-image: url('{{ getCoverUrl() }}'); width: 98%;"
   ></div>

   <h5 class="py-2">{{ news.title }}</h5>
   <p class="news-text">
      {{ news.content }}
   </p>
   <div>
      <a (click)="scroll()" [routerLink]="['/news', news.slug]">— &nbsp; {{ 'SCOPRI_DI_PIU' | translate }}</a>
   </div>
</div>
