<div class="component auction">
   <section>
      <div class="banner container-fluid">
         <div>
            <div class="row">
               <img class="img-fluid col-sm-4 offset-sm-2" src="{{ getCover() }}" alt="" />

               <div class="col-sm-5 col-9 offset-3 offset-sm-0">
                  <h2 class="banner_title">{{ model.banner_title }}</h2>
               </div>
            </div>
         </div>
      </div>
   </section>
   <div class="container-fluid">
      <div class="content">
         <h2 class="mt-4 mb-5 py-5">{{ 'STRUTTURA_ASTA' | translate }}</h2>
         <div class="row">
            <div class="mb-5 col-lg-4" *ngFor="let info of model.cards_top">
               <h3>{{ info.title }}</h3>
               <p class="p-1">{{ info.description }}</p>
            </div>
         </div>
      </div>
   </div>

   <div class="bottom-section pt-5">
      <div class="container-fluid">
         <div class="row my-5 pb-5 bottom-content d-flex justify-content-between">
            <div class="col-lg-4 text-div">
               <h2 class="my-3">{{ 'FUNZIONAMENTO' | translate }}</h2>
               <p>
                  {{ model.content }}
               </p>
            </div>

            <img class="col-lg-4 img-fluid" src="{{ getCover() }}" alt="" />
            <img class="col-lg-4 d-none d-lg-block img-fluid" src="{{ getCover() }}" alt="" />
         </div>
         <div class="row mt-5 py-5">
            <div class="mb-5 col-lg-4" *ngFor="let info of model.cards_bot">
               <h3>{{ info.title }}</h3>
               <p>{{ info.description }}</p>
            </div>
         </div>
      </div>
   </div>

   <div>
      <swiper [config]="config">
         <ng-template *ngFor="let x of bannerURL" #swiper swiperSlide>
            <div
               class="banner-div d-flex justify-content-center align-items-center"
               style="background-image: url('{{ x }}'); height: 100vh"
            ></div>
         </ng-template>
      </swiper>
      <div class="container-fluid row arrow-div">
         <div class="d-sm-block d-none col-1 arrow-prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82">
               <circle id="Ellipse_11" data-name="Ellipse 11" cx="41" cy="41" r="41" fill="#fff" opacity="0.8" />
               <g id="Group_6" data-name="Group 6" transform="translate(-448.428 -334.543)">
                  <line
                     id="Line_1"
                     data-name="Line 1"
                     x2="36.386"
                     transform="translate(475.036 375.043)"
                     fill="none"
                     stroke="#22273f"
                     stroke-miterlimit="10"
                     stroke-width="2"
                  />
                  <path
                     id="Path_20"
                     data-name="Path 20"
                     d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
                     fill="none"
                     stroke="#22273f"
                     stroke-miterlimit="10"
                     stroke-width="2"
                  />
               </g>
            </svg>
         </div>
         <div class="d-sm-block d-none col-1 offset-10 arrow-next">
            <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82">
               <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="41"
                  cy="41"
                  r="41"
                  transform="translate(82 82) rotate(180)"
                  fill="#fff"
                  opacity="0.8"
               />
               <g id="Group_40" data-name="Group 40" transform="translate(530.427 416.543) rotate(180)">
                  <line
                     id="Line_1"
                     data-name="Line 1"
                     x2="36.386"
                     transform="translate(475.036 375.043)"
                     fill="none"
                     stroke="#22273f"
                     stroke-miterlimit="10"
                     stroke-width="2"
                  />
                  <path
                     id="Path_20"
                     data-name="Path 20"
                     d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
                     fill="none"
                     stroke="#22273f"
                     stroke-miterlimit="10"
                     stroke-width="2"
                  />
               </g>
            </svg>
         </div>
      </div>
   </div>
</div>
