import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CollectionTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-page-view',
   templateUrl: './page-view.component.html',
   styleUrls: ['./page-view.component.scss'],
})
export class PageViewComponent extends CollectionTypeComponent implements OnInit {
   environment = environment;
   constructor(
      activatedRoute: ActivatedRoute,
      router: Router,
      titleService: Title,
      metaTagService: Meta,
      strapi: StrapiService,
      meta: MetaService,
   ) {
      super(activatedRoute, router, strapi, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }

   getCoverUrl(): any {
      if (this.model.cover.url) {
         return this.strapi.getImageUrl(this.model.cover.url);
      }
   }
}
