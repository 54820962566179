<div class="component ship-card card" *ngIf="ship">
   <div class="card-body">
      <section class="card-image" style="background-image: url('{{ getCoverUrl() }}');"></section>

      <h3 class="py-3 card-title">{{ ship.name }}</h3>
      <p class="card-text pt-3">
         {{ ship.description }}
      </p>
      <div class="py-4 my-4">
         <a href="ships/{{ ship.slug }}">{{ 'SCOPRI_DI_PIU' | translate }}</a>
      </div>
   </div>
</div>
