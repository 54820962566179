import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import SwiperCore, { Pagination, Swiper, SwiperOptions } from 'swiper';

import { environment } from 'src/environments/environment';

SwiperCore.use([Pagination]);

@Component({
   selector: 'app-news-card',
   templateUrl: './news-card.component.html',
   styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
   @Input() news!: any;

   @ViewChild('swiper', { static: false }) swiper?: any;

   environment = environment;

   config: SwiperOptions = {
      slidesPerView: 1,
      navigation: true,
      pagination: { clickable: true },
      scrollbar: { draggable: true },
      breakpoints: {
         '640': {
            slidesPerView: 2,
            spaceBetween: 20,
         },
         '768': {
            slidesPerView: 3,
            spaceBetween: 40,
         },
         '1024': {
            slidesPerView: 3,
            spaceBetween: 50,
         },
      },
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }

   getCoverUrl(): any {
      return this.news.cover
         ? this.strapi.getImageUrl(this.news.cover.url)
         : 'https://via.placeholder.com/339x226?text=Immagine+non+disponibile';
   }

   scroll() {
      window.scrollTo(0, 0);
   }

   constructor(private strapi: StrapiService, private translate: TranslateService) {
      //do nothing
   }

   async ngOnInit(): Promise<void> {
      // noop
   }
}
