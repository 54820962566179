<div class="component about">
   <section>
      <div class="container-fluid">
         <div class="row">
            <!-- DESKTOP -->
            <div *ngFor="let info of model.cards" class="col-10 offset-2 container-1 justify-content-between card-1 d-none d-lg-flex py-5">
               <!-- <img *ngIf="info.cover" class="img-fluid" src="{{ getCoverUrl(info.cover.url) }}" alt="" /> -->
               <div class="img-fluid banner" style="background-image: url('{{ getCoverUrl(info.cover.url) }}');"></div>
               <div class="text">
                  <h2 class="pb-5">{{ info.title }}</h2>

                  <p>
                     {{ info.description }}
                  </p>
               </div>
            </div>
            <!-- MOBILE -->
            <div *ngFor="let info of model.cards" class="col-12 d-block d-lg-none">
               <h2 class="pb-2">{{ info.title }}</h2>
               <div class="img-fluid banner" style="background-image: url('{{ getCoverUrl(info.cover.url) }}');"></div>

               <p class="pb-4">
                  {{ info.description }}
               </p>
            </div>
         </div>
      </div>

      <section class="fleet">
         <div class="container-fluid">
            <h2 class="py-5">{{ 'LA_NOSTRA_FLOTTA' | translate }}</h2>
            <div class="row">
               <div *ngFor="let ship of ships" class="col-lg-3 mb-5 pb-4">
                  <app-ship-card [ship]="ship"></app-ship-card>
               </div>
            </div>
         </div>
      </section>
   </section>
</div>
