<div class="component page-view">
   <div *ngIf="model.cover" class="banner img-fluid" style="background-image:url('{{ getCoverUrl() }}');">
      <div class="cont"></div>
   </div>
   <div *ngIf="model" class="container-fluid">
      <div class="py-5">
         <h2 class="py-3">{{ model.meta?.title }}</h2>
         <markdown class="publications_md" lineNumbers [start]="5">{{ model.content }}</markdown>
      </div>
   </div>
</div>
