import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SwiperCore, { Pagination, Navigation, SwiperOptions } from 'swiper';
import { environment } from 'src/environments/environment';
import { CollectionTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';
import { TranslateService } from '@ngx-translate/core';

SwiperCore.use([Pagination, Navigation]);

@Component({
   selector: 'app-ship-view',
   templateUrl: './ship-view.component.html',
   styleUrls: ['./ship-view.component.scss'],
})
export class ShipViewComponent extends CollectionTypeComponent implements OnInit {
   @ViewChild('swiper', { static: false }) swiper?: any;
   environment = environment;
   ships: any;
   color = '#ffffff';

   config: SwiperOptions = {
      slidesPerView: 1,
      // autoHeight: true,
      navigation: {
         nextEl: '.arrow-next',
         prevEl: '.arrow-prev',
      },
      pagination: {
         el: '.pagination',
         clickable: true,
         renderBullet: function () {
            return `<span class="dot swiper-pagination-bullet"></span>`;
         },
      },
      scrollbar: { draggable: true },
   };

   configShipsBottom: SwiperOptions = {
      slidesPerView: 1,
      autoHeight: true,
      spaceBetween: 30,
      scrollbar: { draggable: true },
      breakpoints: {
         '992': {
            slidesPerView: 4,
            spaceBetween: 20,
         },
      },
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }

   constructor(
      activatedRoute: ActivatedRoute,
      router: Router,
      meta: MetaService,
      strapi: StrapiService,
      private translate: TranslateService,
   ) {
      super(activatedRoute, router, strapi, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.ships = await this.strapi.getSingleType('ships', this.translate.currentLang);
   }

   async getCoverUrl(url: string) {
      return await this.strapi.getImageUrl(url);
   }
}
