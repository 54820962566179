<div class="component navbar-component sticky-top">
   <nav *ngIf="settings" class="navbar navbar-expand-lg">
      <div class="container-fluid">
         <a (click)="scroll()" class="navbar-brand" routerLink="/">
            <img class="img-fluid d-none d-lg-block" src="{{ getCoverUrl(settings.logo.url) }}" alt="" />

            <img
               class="img-fluid d-block d-lg-none logo-mobile"
               src="{{ gfg ? getCoverUrl(settings.logo_mobile.url) : getCoverUrl(settings.logo_mobile_nav.url) }}"
               alt=""
            />
         </a>

         <span
            type="button"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!gfg"
            aria-controls="collapseExample"
            class="{{ gfg ? 'not-collapsed' : 'collapsed' }} d-block d-lg-none"
         >
            <span class="icon-bar"></span>
         </span>

         <!------------------------------ DESKTOP ------------------------------>
         <div class="d-none d-lg-flex collapse navbar-collapse">
            <a (click)="scroll()" class="navbar-brand" routerLink="/">
               <img class="img-fluid d-block d-lg-none logo-mobile" src="{{ getCoverUrl(settings.logo_mobile.url) }}" alt="" />
            </a>

            <ul *ngIf="model" class="navbar-nav me-auto mb-2 mb-lg-0">
               <li class="nav-item px-2" *ngFor="let item of model.items">
                  <a (click)="scroll()" class="nav-link active" *ngIf="item.type == 'SINGLE_TYPE'" routerLink="/{{ item.location }}">{{
                     item.title
                  }}</a>
                  <a
                     (click)="scroll()"
                     class="nav-link active"
                     *ngIf="item.type == 'PAGE'"
                     [routerLink]="['/page', item.location, item.id]"
                     >{{ item.title }}</a
                  >
                  <a
                     (click)="scroll()"
                     class="nav-link active"
                     *ngIf="item.type == 'NEWS'"
                     [routerLink]="['/news', item.location, item.id]"
                     >{{ item.title }}</a
                  >
                  <a
                  (click)="collapse.toggle()"
                  class="nav-link active"
                  *ngIf="item.type == 'URL'"
                  target="_blank"
                  href="{{ item.location }}"
                  >{{ item.title }}</a
               >
               </li>

               <li *ngIf="translate.getLangs().length > 1" class="nav-item">
                  <div ngbDropdown class="d-inline-block drop nav-link">
                     <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{ translate.currentLang }}</button>
                     <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem *ngFor="let lang of translate.getLangs()" ngbDropdownItem (click)="useLanguage(lang)">
                           {{ lang }}
                        </button>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
         <!-------------------------------------------------------------------->

         <!------------------------------ MOBILE ------------------------------>
         <div #collapse="ngbCollapse" [(ngbCollapse)]="gfg" class="overlay">
            <div class="d-block d-lg-none" style="height: 100vh">
               <ul *ngIf="model" class="navbar-nav me-auto mb-2 mb-lg-0 overlay-content">
                  <li class="nav-item px-2" *ngFor="let item of model.items">
                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'SINGLE_TYPE'"
                        routerLink="/{{ item.location }}"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'PAGE'"
                        [routerLink]="['/page', item.location, item.id]"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'NEWS'"
                        [routerLink]="['/news', item.location, item.id]"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'URL'"
                        target="_blank"
                        href="{{ item.location }}"
                        >{{ item.title }}</a
                     >
                  </li>
               </ul>
               <div class="blue" style="position: relative"></div>
            </div>
         </div>
         <!-------------------------------------------------------------------->
      </div>
   </nav>
</div>
