import { Component, Input, OnInit } from '@angular/core';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-ship-card',
   templateUrl: './ship-card.component.html',
   styleUrls: ['./ship-card.component.scss'],
})
export class ShipCardComponent implements OnInit {
   @Input() ship: any;
   environment = environment;

   constructor(private strapi: StrapiService) {
      // do nothing.
   }

   ngOnInit(): void {
      // do nothing.
   }

   getCoverUrl(): any {
      return this.ship.cover.length
         ? this.strapi.getImageUrl(this.ship.cover[0].url)
         : 'https://via.placeholder.com/339x226?text=Immagine+non+disponibile';
   }
}
