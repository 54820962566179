<div class="component contacts">
   <div class="container-fluid">
      <div class="justify-content-between py-5">
         <div class="row">
            <h2 class="d-lg-none d-block">{{ model.title }}</h2>
            <div class="col-lg-6 col-12" id="map"></div>
            <p class="d-lg-none d-block py-3">{{ model.content }}</p>
            <div class="col-4 offset-1 d-lg-block d-none">
               <h2>{{ model.title }}</h2>
               <p>{{ model.content }}</p>
            </div>
         </div>
      </div>
   </div>
</div>
