<div class="component news-view">
   <div class="container-fluid mt-5 pt-3">
      <div class="content">
         <div class="news_view_container row">
            <div class="col-md-12 col-lg-2 pb-5">
               <a routerLink="/news"><i class="bi bi-arrow-left"></i> torna indietro</a>
            </div>
            <div class="col-lg-8 col-md-12 inner-content">
               <h4 class="date pb-2">{{ luxon.DateTime.fromISO(model.created_at).setLocale(model.locale).toFormat('d MMMM yyyy') }}</h4>
               <h1 class="pb-3">{{ model.title }}</h1>

               <div class="news-view-banner img-fluid" style="background-image: url('{{ getCoverUrl() }}');"></div>

               <p class="mt-3">
                  {{ model.content }}
               </p>
            </div>
         </div>
         <div class="outer_content my-3 py-5">
            <h2 class="py-3">Altre news</h2>
            <div>
               <swiper [config]="config">
                  <ng-template #swiper swiperSlide *ngFor="let info of news">
                     <app-news-card [news]="info"></app-news-card>
                  </ng-template>
               </swiper>
            </div>
         </div>
      </div>
   </div>
</div>
