import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';

@Component({
   selector: 'app-google-analytics-gtag',
   templateUrl: './google-analytics-gtag.component.html',
   styleUrls: ['./google-analytics-gtag.component.scss'],
})
export class GoogleAnalyticsGtagComponent implements OnInit {
   @Input() key!: any;
   trackingCode: any;

   constructor(
      @Inject(PLATFORM_ID) private readonly platformId: any,
      private readonly renderer: Renderer2,
      private readonly el: ElementRef,
   ) {}

   async ngOnInit(): Promise<any> {
      this.trackingCode = await this.key;

      // BROWSER
      if (isPlatformServer(this.platformId)) {
         const script = this.renderer.createElement('script') as HTMLScriptElement;
         script.src = `//www.googletagmanager.com/gtag/js?id=${this.trackingCode}`;
         script.async = true;
         this.renderer.appendChild(this.el.nativeElement, script);

         const script2 = this.renderer.createElement('script') as HTMLScriptElement;
         const scriptBody = this.renderer.createText(`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
    
            gtag('config', '${this.trackingCode}');
          `);
         this.renderer.appendChild(script2, scriptBody);
         this.renderer.appendChild(this.el.nativeElement, script2);
      }
   }
}
