<div class="row">
   <div class="col-1 offset-1 arrow-prev d-none d-lg-flex justify-content-start align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="44.993" height="39.265" viewBox="0 0 44.993 39.265">
         <g id="Group_6" data-name="Group 6" transform="translate(-466.428 -355.411)">
            <line
               id="Line_1"
               data-name="Line 1"
               x2="36.386"
               transform="translate(475.036 375.043)"
               fill="none"
               stroke="#22273f"
               stroke-miterlimit="10"
               stroke-width="2"
            />
            <path
               id="Path_20"
               data-name="Path 20"
               d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
               fill="none"
               stroke="#22273f"
               stroke-miterlimit="10"
               stroke-width="2"
            />
         </g>
      </svg>
   </div>

   <div class="col-lg-8">
      <div *ngIf="status">
         <swiper [config]="config">
            <ng-template #swiper swiperSlide *ngFor="let image of status.lots">
               <a target="_blank" href="{{environment.app}}/lot/{{ image }}">
                  <img class="img-fluid" src="{{environment.api}}/lot/{{ image }}/image" />
               </a>
            </ng-template>
         </swiper>
      </div>
      <div class="pagination offset-5 col-2 p-5 d-sm-block d-none"></div>

      <div class="row">
         <div *ngFor="let info of model.paragraph" class="pb-5 mt-3 mb-3 col-lg-6">
            <h2 class="pt-4 pb-3">{{ info.title }}</h2>
            <p class="pb-2">
               {{ info.description }}
            </p>
            <a href="{{ info.location }}">— &nbsp; {{ 'SCOPRI_DI_PIU' | translate }}</a>
         </div>
      </div>
   </div>

   <div class="d-none d-lg-flex justify-content-end col-1 arrow-next align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="44.994" height="39.265" viewBox="0 0 44.994 39.265">
         <g id="Group_5" data-name="Group 5" transform="translate(511.422 394.676) rotate(180)">
            <line
               id="Line_1"
               data-name="Line 1"
               x2="36.386"
               transform="translate(475.036 375.043)"
               fill="none"
               stroke="#22273f"
               stroke-miterlimit="10"
               stroke-width="2"
            />
            <path
               id="Path_20"
               data-name="Path 20"
               d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
               fill="none"
               stroke="#22273f"
               stroke-miterlimit="10"
               stroke-width="2"
            />
         </g>
      </svg>
   </div>
</div>
