import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class SmartAuctionService {
   api_url = environment.api;

   constructor(protected http: HttpClient) {
      // noop
   }

   async getStatus(): Promise<any> {
      return await this.http.get<any>(this.api_url + '/auction/status').toPromise();
   }
}
