import { Component, Input, OnInit, Pipe } from '@angular/core';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { SmartAuctionService } from 'src/app/services/smart-auction.service';

@Component({
   selector: 'app-data-aggregate',
   templateUrl: './data-aggregate.component.html',
   styleUrls: ['./data-aggregate.component.scss'],
})
export class DataAggregateComponent implements OnInit {
   @Input() model: any;

   status: any;
   sales!: string;
   days!: string;
   products!: string;

   constructor(private strapi: StrapiService, private smartAuction: SmartAuctionService) {
      //do nothing
   }

   async ngOnInit(): Promise<void> {
      this.status = await this.smartAuction.getStatus();

      this.sales = new Intl.NumberFormat('en-GB', {
         notation: 'compact',
         compactDisplay: 'short',
      } as any).format(this.status.tot_sales);

      this.days = new Intl.NumberFormat('en-GB', {
         notation: 'compact',
         compactDisplay: 'short',
      } as any).format(this.status.tot_days);

      this.products = new Intl.NumberFormat('en-GB', {
         notation: 'compact',
         compactDisplay: 'short',
      } as any).format(this.status.tot_products);
   }
}
