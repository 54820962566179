<div *ngIf="status" class="banner d-flex justify-content-center">
   <!-- <img class="img-fluid image-right" src="/assets/banner2.png" alt="" /> -->
   <div class="container-fluid">
      <div class="row">
         <div class="stats-div d-lg-flex b-block justify-content-around text-align-center">
            <div class="stats text-align-center py-4 my-4">
               <h3>{{ sales }}</h3>
               <h4>{{ model.auction_numbers[0].key }}</h4>
            </div>
            <div class="stats text-align-center py-4 my-4">
               <h3>{{ days }}</h3>
               <h4>{{ model.auction_numbers[1].key }}</h4>
            </div>
            <div class="stats text-align-center py-4 my-4">
               <h3>{{ products }}</h3>
               <h4>{{ model.auction_numbers[2].key }}</h4>
            </div>
         </div>
      </div>
   </div>
   <!-- <img class="img-fluid image-left" src="/assets/banner2.png" alt="" /> -->
</div>
