<div class="component footer">
   <div *ngIf="settings" class="footer-container pt-5">
      <div class="container-fluid">
         <div class="inner-div">
            <div class="row">
               <img *ngIf="settings" class="img-fluid p-5 col-12 col-md-4 offset-md-4" src="{{ getCoverUrl() }}" alt="" />
            </div>
            <div class="pt-5 row d-flex footer-div align-center">
               <ul class="d-column d-lg-flex text-center footer-links justify-content-between">
                  <li class="py-3" *ngFor="let item of model.items">
                     <a routerLink="{{ item.location }}">{{ item.title }}</a>
                  </li>
               </ul>
            </div>
            <hr />
            <div class="row">
               <div *ngIf="footerInfo" class="pb-3 footer-links-mid d-column d-lg-flex justify-content-between col-lg-12">
                  <div class="py-3" *ngFor="let info of footerInfo.footer">
                     <h4 class="py-3">{{ info.title }}</h4>
                     <p>{{ info.info_1 }}</p>
                     <p>{{ info.info_2 }}</p>
                     <p>{{ info.info_3 }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="footer-links-bottom">
         <div class="container-fluid">
            <div style="align-items: center" class="d-column d-lg-flex text-center justify-content-between p-3">
               <p>{{ settings.footer_title }}</p>
               <div class="d-column d-lg-flex text-center py-3">
                  <a routerLink="/page/privacy" translate>PRIVACY_POLICY</a>
                  <!-- <a routerLink="/page/cookies" translate>COOKIE_POLICY</a>
                  <a routerLink="/page/terms" translate>TERMS</a> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
