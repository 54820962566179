import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { About } from './components/about/about.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselComponent } from './components/homepage/carousel/carousel.component';
import { DataAggregateComponent } from './components/homepage/data-aggregate/data-aggregate.component';
import { DataDailyComponent } from './components/homepage/data-daily/data-daily.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { CookieService } from '@sciamlab/ng-common-strapi';
import { CONFIG } from './config';
import { NgCommonStrapiModule, StrapiService } from '@sciamlab/ng-common-strapi';
import { ContactsComponent } from './components/contacts/contacts.component';
import { NgMarqueeModule } from 'ng-marquee';
import { PageViewComponent } from './components/page-view/page-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { AuctionComponent } from './components/auction/auction.component';
import { ParticipateComponent } from './components/participate/participate.component';
import { ErrorComponent } from './components/error/error.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { ShipViewComponent } from './components/ship-view/ship-view.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ShipCardComponent } from './components/ship-card/ship-card.component';
import { GoogleAnalyticsGtagComponent } from './components/google-analytics-gtag/google-analytics-gtag.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { translate } from '@angular/localize/src/utils';
import { environment } from 'src/environments/environment';

/* Best practices for Error handling in Angular
 * https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
 */

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler implements ErrorHandler {
   constructor() {
      // do nothing.
   }

   handleError(error: any) {
      /* Angular custom error handler not getting error type from promise
       * https://stackoverflow.com/a/54826258
       */
      if (error.promise && error.rejection) {
         // Promise rejection wrapped by zone.js
         error = error.rejection;
      }
      if (error.error) {
         error = error.error;
      }

      let message = null;
      const stackTrace = null;

      if (error instanceof HttpErrorResponse) {
         // Server Error
         message = error.message;
      } else {
         // Client Error
         message = error.message || error.toString();
      }

      //   this.snackbarService.error(message);

      // Always log errors
      // console.error(message, stackTrace);
      console.error(error);
   }
}

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      FooterComponent,
      About,
      HomepageComponent,
      CarouselComponent,
      DataAggregateComponent,
      DataDailyComponent,
      NewsCardComponent,

      ContactsComponent,
      PageViewComponent,
      NewsListComponent,
      NewsViewComponent,
      AuctionComponent,
      ParticipateComponent,
      ErrorComponent,
      ShipViewComponent,
      ShipCardComponent,
      GoogleAnalyticsGtagComponent,
   ],
   imports: [
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      NgbModule,
      HttpClientModule,
      // TranslateModule.forRoot({
      //    loader: {
      //       provide: TranslateLoader,
      //       useFactory: (strapi: StrapiService) => {
      //          return new CustomLoader(strapi);
      //       },
      //       deps: [StrapiService],
      //    },
      // }),
      NgCommonStrapiModule.forRoot({
         strapi: {
            url: environment.cms,
         },
      }),
      NgMarqueeModule,
      SwiperModule,
      FormsModule,
      TransferHttpCacheModule,
      MarkdownModule.forRoot({
         loader: HttpClient, // optional, only if you use [src] attribute
         markedOptions: {
            provide: MarkedOptions,
            useValue: {
               gfm: true,
               breaks: false,
               pedantic: false,
               smartLists: true,
               smartypants: false,
            },
         },
      }),
   ],
   providers: [
      {
         provide: ErrorHandler,
         useClass: CustomErrorHandler,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
