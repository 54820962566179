import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { About } from './components/about/about.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { PageViewComponent } from './components/page-view/page-view.component';
import { AuctionComponent } from './components/auction/auction.component';
import { ParticipateComponent } from './components/participate/participate.component';
import { ErrorComponent } from './components/error/error.component';
import { ShipViewComponent } from './components/ship-view/ship-view.component';
import { SingleTypeResolver, CollectionTypeResolver, CollectionTypeListResolver } from '@sciamlab/ng-common-strapi';

const routes: Routes = [
   {
      path: 'about',
      component: About,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'about',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: '',
      component: HomepageComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'home',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: 'contacts',
      component: ContactsComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'contacts',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: 'page',
      children: [
         {
            path: ':id',
            component: PageViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'pages',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'ships',
      children: [
         {
            path: ':id',
            component: ShipViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'ships',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'news',
      children: [
         {
            path: '',
            component: NewsListComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
         {
            path: ':id',
            component: NewsViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'auction',
      component: AuctionComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'auction',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: 'participate',
      component: ParticipateComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'participate',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },

   {
      path: 'error/:code',
      component: ErrorComponent,
   },
   {
      path: '**',
      component: ErrorComponent,
   },
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         initialNavigation: 'enabled',
         onSameUrlNavigation: 'reload',
      }),
   ],
   exports: [RouterModule],
   providers: [SingleTypeResolver, CollectionTypeResolver],
})
export class AppRoutingModule {}
