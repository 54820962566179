import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CollectionTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { environment } from 'src/environments/environment';
import * as luxon from 'luxon';

SwiperCore.use([Pagination, Navigation]);

@Component({
   selector: 'app-news-view',
   templateUrl: './news-view.component.html',
   styleUrls: ['./news-view.component.scss'],
})
export class NewsViewComponent extends CollectionTypeComponent implements OnInit {
   @ViewChild('swiper', { static: false }) swiper?: any;
   news: any[] = [];
   environment = environment;
   luxon = luxon;

   config: SwiperOptions = {
      slidesPerView: 1,
      navigation: false,
      pagination: false,
      scrollbar: { draggable: true },
      breakpoints: {
         '768': {
            slidesPerView: 3,
            spaceBetween: 20,
         },
         '1024': {
            slidesPerView: 3,
            spaceBetween: 20,
         },
      },
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }

   constructor(
      activatedRoute: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      meta: MetaService,
      private translate: TranslateService,
   ) {
      super(activatedRoute, router, strapi, meta);
   }

   async ngOnInit(): Promise<void> {
      this.news = await this.strapi.filterNews(undefined, 0, 3, 'published_at:DESC', this.translate.currentLang);
      await super.ngOnInit();
   }

   getCoverUrl() {
      return this.model.cover
         ? this.strapi.getImageUrl(this.model.cover.url)
         : 'https://via.placeholder.com/500x500?text=Immagine+non+disponibile';
   }
}
