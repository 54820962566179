import { Component, Injectable, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SingleTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';
import { Map, Polygon, Circle, Marker, icon, Icon } from 'leaflet';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
   selector: 'app-contacts',
   templateUrl: './contacts.component.html',
   styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent extends SingleTypeComponent implements OnInit {
   public message!: string;
   private map!: Map;
   settings: any;
   lat!: number;
   lon!: number;

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      //private leaflet: LeafletService,
      meta: MetaService,
      @Inject(PLATFORM_ID) private platformId: any,
   ) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      if (isPlatformBrowser(this.platformId)) {
         const L = await import('leaflet');
         const iconRetinaUrl = 'assets/marker-icon-2x.png';
         const iconUrl = 'assets/marker-icon.png';
         const shadowUrl = 'assets/marker-shadow.png';
         const iconDefault = L.icon({
            iconRetinaUrl,
            iconUrl,
            shadowUrl,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41],
         });
         L.Marker.prototype.options.icon = iconDefault;

         this.map = L.map('map').setView([this.model.lat, this.model.lon], this.model.zoom);

         L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
         }).addTo(this.map);

         L.marker([this.model.lat, this.model.lon]).addTo(this.map);
      }
   }
}
