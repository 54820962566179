<div class="component news-list">
   <div class="container-fluid">
      <div class="content">
         <h2 class="pt-3 pb-4">News</h2>
         <div class="row">
            <app-news-card class="col-md-4 pb-5 mb-5 mt-1 pt-1" *ngFor="let info of news" [news]="info"></app-news-card>
         </div>

         <div class="d-flex justify-content-center pb-5 mb-3">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshNews()">
            </ngb-pagination>

            <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshNews()">
               <option [value]="2">2 {{ 'PER_PAGINA' | translate }}</option>
               <option [value]="4">4 {{ 'PER_PAGINA' | translate }}</option>
               <option [value]="6">6 {{ 'PER_PAGINA' | translate }}</option>
            </select> -->
         </div>
      </div>
   </div>
</div>
