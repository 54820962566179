<div class="component ship-view">
   <section class="py-5">
      <div class="container-fluid">
         <div class="row">
            <a href="#" class="col-md-12 col-lg-2"> Torna indietro</a>
            <h1 class="text-center col-md-12 col-lg-6 offset-lg-1">{{ model.name }}</h1>
         </div>

         <div class="row pb-5">
            <div class="col-lg-8 offset-lg-2 col-md-12">
               <div class="row">
                  <div class="col-1 offset-1 arrow-prev d-none d-lg-flex justify-content-start align-items-center">
                     <svg xmlns="http://www.w3.org/2000/svg" width="44.993" height="39.265" viewBox="0 0 44.993 39.265">
                        <g id="Group_6" data-name="Group 6" transform="translate(-466.428 -355.411)">
                           <line
                              id="Line_1"
                              data-name="Line 1"
                              x2="36.386"
                              transform="translate(475.036 375.043)"
                              fill="none"
                              stroke="#22273f"
                              stroke-miterlimit="10"
                              stroke-width="2"
                           />
                           <path
                              id="Path_20"
                              data-name="Path 20"
                              d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
                              fill="none"
                              stroke="#22273f"
                              stroke-miterlimit="10"
                              stroke-width="2"
                           />
                        </g>
                     </svg>
                  </div>

                  <div class="col-lg-8">
                     <div>
                        <swiper [config]="config">
                           <ng-template #swiper swiperSlide *ngFor="let x of model.cover">
                              <a target="_blank">
                                 <img class="img-fluid carousel_img" src="{{ getCoverUrl(x.url) }}" />
                              </a>
                           </ng-template>
                        </swiper>
                     </div>
                     <div class="pagination offset-5 col-2 p-5 d-sm-block d-none"></div>

                     <div class="row">
                        <div *ngFor="let info of model.paragraph" class="pb-5 mt-3 mb-3 col-lg-6">
                           <h2 class="pt-4 pb-3">{{ info.title }}</h2>
                           <p class="pb-2">
                              {{ info.description }}
                           </p>
                           <a href="{{ info.location }}">— &nbsp; {{ 'SCOPRI_DI_PIU' | translate }}</a>
                        </div>
                     </div>
                  </div>

                  <div class="d-none d-lg-flex justify-content-end col-1 arrow-next align-items-center">
                     <svg xmlns="http://www.w3.org/2000/svg" width="44.994" height="39.265" viewBox="0 0 44.994 39.265">
                        <g id="Group_5" data-name="Group 5" transform="translate(511.422 394.676) rotate(180)">
                           <line
                              id="Line_1"
                              data-name="Line 1"
                              x2="36.386"
                              transform="translate(475.036 375.043)"
                              fill="none"
                              stroke-miterlimit="10"
                              stroke-width="2"
                           />
                           <path
                              id="Path_20"
                              data-name="Path 20"
                              d="M484.962,393.969l-16.55-16.55a3.361,3.361,0,0,1,0-4.752l16.55-16.549"
                              fill="none"
                              stroke-miterlimit="10"
                              stroke-width="2"
                           />
                        </g>
                     </svg>
                  </div>
               </div>

               <div *ngIf="model.cover.length === 0">
                  <img class="img-fluid" src="https://via.placeholder.com/2600x1300?text=Immagine+non+disponibile" alt="" />
               </div>
            </div>
         </div>

         <div class="row py-5">
            <div class="specs col-lg-4 col-md-12 offset-lg-2 pb-5">
               <h2 class="pb-2">Scheda tecnica</h2>
               <div class="row">
                  <div class="d-flex" *ngFor="let specs of model.specs">
                     <p translate class="w-50">{{ specs.key }}</p>
                     <p class="w-50 value">{{ specs.value }}</p>
                  </div>
               </div>
            </div>
            <div class="specs col-lg-4 col-md-12 pb-4">
               <h2 class="pb-2">Specifiche di costruzione</h2>
               <p class="desc">{{ model.description }}</p>
            </div>
         </div>

         <div class="row py-4" *ngIf="model.captain_name">
            <div class="col-lg-8 offset-lg-2">
               <h2 class="pb-2">Capitano</h2>
               <div class="d-lg-flex d-block justify-content-between">
                  <img
                     class="img-fluid captain_image mb-5"
                     src="{{
                        model.captain_image
                           ? getCoverUrl(model.captain_image.url)
                           : 'https://via.placeholder.com/300x375?text=Immagine+non+disponibile'
                     }}"
                     alt=""
                  />

                  <div class="captain_desc">
                     <h3>{{ model.captain_name }}</h3>
                     <p>{{ model.captain_abstract }}</p>
                  </div>
               </div>
            </div>
         </div>

         <div class="row">
            <div class="ships col">
               <h2 class="pt-5">Altre imbarcazioni</h2>
            </div>
         </div>

         <div class="row pb-5">
            <div>
               <swiper [config]="configShipsBottom">
                  <ng-template #swiper swiperSlide *ngFor="let ship of ships" class="col-lg-3 pt-5">
                     <app-ship-card [ship]="ship"></app-ship-card>
                  </ng-template>
               </swiper>
            </div>
         </div>
      </div>
      <!-- <hr class="my-5" /> -->
   </section>
</div>
