import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SingleTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-participate',
   templateUrl: './participate.component.html',
   styleUrls: ['./participate.component.scss'],
})
export class ParticipateComponent extends SingleTypeComponent implements OnInit {
   environment = environment;
   settings: any;
   title: any;

   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      // this.titleService.setTitle(this.model.meta ? this.model.meta.title : this.settings.meta.title);

      // this.metaTagService.updateTag({
      //    name: this.model.meta ? this.model.meta.title : this.settings.meta.title,
      //    content: this.model.meta ? this.model.meta.description : this.settings.meta.description,
      // });
   }

   getCoverUrl(url: string) {
      return this.strapi.getImageUrl(url);
   }
}
