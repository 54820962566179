import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SingleTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-homepage',
   templateUrl: './homepage.component.html',
   styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent extends SingleTypeComponent implements OnInit {
   environment = environment;
   news: any;

   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      this.news = await this.strapi.filterNews(undefined, 0, 3, 'published_at:DESC', this.translate.currentLang);
   }

   getCoverUrl(): any {
      return this.strapi.getImageUrl(this.model.banner.url);
   }
}
