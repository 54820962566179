import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SingleTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';
import SwiperCore, { Pagination, Navigation, SwiperOptions } from 'swiper';
import { Meta, Title } from '@angular/platform-browser';

SwiperCore.use([Pagination, Navigation]);

@Component({
   selector: 'app-auction',
   templateUrl: './auction.component.html',
   styleUrls: ['./auction.component.scss'],
})
export class AuctionComponent extends SingleTypeComponent implements OnInit {
   @ViewChild('swiper', { static: false }) swiper?: any;
   environment = environment;
   bannerURL: any[] = [];

   config: SwiperOptions = {
      slidesPerView: 1,
      scrollbar: { draggable: true },
      navigation: {
         nextEl: '.arrow-next',
         prevEl: '.arrow-prev',
      },
      pagination: true,
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }
   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.getCoverUrl();
   }

   getCoverUrl(): any {
      this.model.cover.map((el: any) => {
         this.bannerURL.push((el.url.startsWith('/') ? environment.cms : '') + el.url) as any;
      });
   }

   getCover() {
      return this.strapi.getImageUrl(this.model.cover[0].url);
   }
}
