import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { SmartAuctionService } from 'src/app/services/smart-auction.service';
import { environment } from 'src/environments/environment';
import SwiperCore, { Pagination, Navigation, SwiperOptions } from 'swiper';
SwiperCore.use([Pagination, Navigation]);

@Component({
   selector: 'app-carousel',
   templateUrl: './carousel.component.html',
   styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
   @Input() model: any;
   @ViewChild('swiper', { static: false }) swiper?: any;

   status: any;
   environment = environment;

   config: SwiperOptions = {
      slidesPerView: 1,
      autoHeight: true,
      navigation: {
         nextEl: '.arrow-next',
         prevEl: '.arrow-prev',
      },
      pagination: {
         el: '.pagination',
         clickable: true,
         renderBullet: function () {
            return `<span class="dot swiper-pagination-bullet"></span>`;
         },
      },
      scrollbar: { draggable: true },
   };

   constructor(private strapi: StrapiService, private smartAuction: SmartAuctionService) {
      //do nothing.
   }

   async ngOnInit(): Promise<void> {
      this.status = await this.smartAuction.getStatus();
   }
}
