<div class="component participate">
   <div class="container-fluid">
      <div class="content pb-5">
         <h2 translate class="font-weight-bold pb-5">COME_PARTECIPARE</h2>
         <div class="row pb-5">
            <div class="text-center logo-div col-md-4 py-5" *ngFor="let info of model.paragraph_top">
               <img *ngFor="let img of info.cover" class="img-fluid" src="{{ getCoverUrl(img.url) }}" alt="" />

               <div>
                  <h3 class="card-title font-weight-bold py-4">{{ info.title }}</h3>
               </div>
               <div>
                  <ul class="card-text">
                     <li>{{ info.content_1 }}</li>
                     <li>{{ info.content_2 }}</li>
                     <li>{{ info.content_3 }}</li>
                     <li>{{ info.content_4 }}</li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="bottom-section py-5">
      <div class="container-fluid">
         <div class="content bottom-content d-flex justify-content-between">
            <div class="text-div">
               <h3 translate class="pb-5">MODULI_DA_SCARICARE</h3>
            </div>
         </div>

         <div class="bottom-section-svg-div row">
            <div class="logo-div text-center col-md-4 py-5" *ngFor="let info of model.paragraph_bottom">
               <img *ngFor="let img of info.cover" class="img-fluid" src="{{ getCoverUrl(img.url) }}" alt="" />
               <div class="logo-div-bottom">
                  <div>
                     <h3 class="card-title py-4">{{ info.title }}</h3>
                  </div>
                  <div>
                     <ul class="card-text">
                        <li>{{ info.content_1 }}</li>
                        <li>{{ info.content_2 }}</li>
                        <li>{{ info.content_3 }}</li>
                        <li>{{ info.content_4 }}</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
