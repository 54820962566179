import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import * as luxon from 'luxon';
import { DateTime } from 'luxon';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { SmartAuctionService } from 'src/app/services/smart-auction.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
   selector: 'app-data-daily',
   templateUrl: './data-daily.component.html',
   styleUrls: ['./data-daily.component.scss'],
})
export class DataDailyComponent implements OnInit {
   @Input() model: any;
   closedAuction = false;
   luxon = luxon;
   time!: string;
   status: any;
   public localDatetime: any;

   constructor(private smartAuction: SmartAuctionService, @Inject(PLATFORM_ID) private platformId: any) {
      //do nothing.
   }

   async ngOnInit(): Promise<void> {
      if (isPlatformBrowser(this.platformId)) {
         setInterval(() => {
            this.localDatetime = DateTime.local().setZone('Europe/Rome').toISO();
            this.time = this.localDatetime;
         }, 1000);
      }

      this.status = await this.smartAuction.getStatus();
   }
}
