import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SingleTypeComponent, StrapiService, MetaService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-about',
   templateUrl: './about.component.html',
   styleUrls: ['./about.component.scss'],
})
export class About extends SingleTypeComponent implements OnInit {
   ships: any[] = [];
   environment = environment;

   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      this.ships = await this.strapi.filterCollectionType('ships');
   }

   getCoverUrl(url: string) {
      return this.strapi.getImageUrl(url);
   }
}
