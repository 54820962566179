import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-nav',
   templateUrl: './nav.component.html',
   styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
   model: any;
   settings: any;
   langChangeSubscription!: Subscription;
   environment = environment;
   clicked = false;
   public gfg = true;
   langs!: string[];

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private strapi: StrapiService,
      public translate: TranslateService,
   ) {
      this.settings = this.strapi.getSettings();
   }

   async ngOnInit(): Promise<void> {
      this.langChangeSubscription = this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
         this.model = await this.strapi.getSingleType('mainmenu', this.translate.currentLang);

         const currentUrl = this.router.url;
         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
         this.router.onSameUrlNavigation = 'reload';
         this.router.navigate([currentUrl]);
      });
      this.model = await this.strapi.getSingleType('mainmenu', this.translate.currentLang);
      this.langs = this.translate.getLangs();
   }

   useLanguage(lang: string): void {
      this.translate.use(lang);
   }

   // openNav(): any {
   //    this.clicked = !this.clicked;
   // }

   getCoverUrl(url: string) {
      return this.strapi.getImageUrl(url);
   }

   scroll() {
      window.scrollTo(0, 0);
   }
}
