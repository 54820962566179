<div>
   <section class="component homepage">
      <div class="banner-div" style="background-image:url('{{ getCoverUrl() }}');">
         <div class="container-fluid">
            <h2>{{ settings.title }}</h2>
         </div>
      </div>

      <div class="marquee" *ngIf="model.marquee">
         <div class="marquee__inner" aria-hidden="true">
            <span>{{ model.marquee }}</span>
            <span>{{ model.marquee }}</span>
            <span>{{ model.marquee }}</span>
            <span>{{ model.marquee }}</span>
         </div>
      </div>
   </section>
   <section class="container-fluid">
      <app-carousel [model]="model" class="component carousel-comp"></app-carousel>
   </section>
   <section>
      <app-data-aggregate [model]="model" class="component data-aggr"></app-data-aggregate>
   </section>
   <section class="container-fluid">
      <app-data-daily [model]="model" class="component data-day"></app-data-daily>
      <h2 class="title pt-5">News</h2>
      <div class="d-flex justify-content-between">
         <div class="news_card d-flex justify-content-between row">
            <app-news-card class="content col-12 col-md-4 py-3 pb-5 px-3" *ngFor="let info of news" [news]="info"></app-news-card>
         </div>
      </div>
      <div class="link-div py-5 d-none d-lg-block">
         <a translate href="news">TUTTE_LE_NEWS </a>
      </div>
   </section>
</div>
