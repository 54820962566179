<div class="component data-day">
   <div class="container-fluid d-none d-lg-block">
      <div *ngIf="status" class="row my-5">
         <div class="col-lg-8 offset-lg-2 text-align-center">
            <div class="info_title">
               <h3 translate>ASTA_GIORNALIERA</h3>
               <h3 style="color: #2282ff">
                  {{ luxon.DateTime.fromISO(status.auction.date_start).setLocale(model.locale).toFormat('d MMMM yyyy') }}
               </h3>
               <p style="color: #2282ff">
                  {{ time | date: 'h:mm:ss' }}
               </p>
            </div>
            <div class="info_status pt-4">
               <div class="status p-5 {{ status.auction.date_close ? 'closed' : '' }}">
                  <p translate>ASTA_STATO</p>
                  <h3 translate>{{ status.auction.date_close ? 'ASTA_CHIUSA' : 'ASTA_APERTA' }}</h3>
               </div>
               <div class="time d-lg-flex d-lg-block justify-content-between">
                  <div class="p-4 {{ status.auction.date_close ? 'w-100 w-lg-50' : 'w-100' }}" style="border-right: 0.9px solid black">
                     <p translate>ASTA_INIZIO</p>
                     <h3>{{ status.auction.date_start | date: 'h:mm:ss' }}</h3>
                  </div>
                  <div class="p-4 {{ status.auction.date_close ? 'w-100 w-lg-50' : 'd-none' }}">
                     <p translate>ASTA_FINE</p>
                     <h3>
                        {{ status.auction.date_close | date: 'h:mm:ss' }}
                     </h3>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="status" class="row my-5 d-block d-lg-none">
      <div class="col-12 text-align-center">
         <div class="info_title">
            <h3 class="pt-5" translate>ASTA_GIORNALIERA</h3>
            <h3 style="color: #2282ff">
               {{ luxon.DateTime.fromISO(status.auction.date_start).setLocale(model.locale).toFormat('d MMMM yyyy') }}
            </h3>
            <p style="color: #2282ff">
               {{ time | date: 'h:mm:ss' }}
            </p>
         </div>
         <div class="info_status pt-4">
            <div class="status p-5 {{ status.auction.date_close ? 'closed' : '' }}">
               <p translate>ASTA_STATO</p>
               <h3 translate>{{ status.auction.date_close ? 'ASTA_CHIUSA' : 'ASTA_APERTA' }}</h3>
            </div>
            <div class="time d-lg-flex d-lg-block justify-content-between">
               <div class="p-4 {{ status.auction.date_close ? 'w-100 w-lg-50' : 'w-100' }}" style="border-right: 0.9px solid black">
                  <p translate>ASTA_INIZIO</p>
                  <h3>{{ status.auction.date_start | date: 'h:mm:ss' }}</h3>
               </div>
               <div class="p-4 {{ status.auction.date_close ? 'w-100 w-lg-50' : 'd-none' }}">
                  <p translate>ASTA_FINE</p>
                  <h3>
                     {{ status.auction.date_close | date: 'h:mm:ss' }}
                  </h3>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
